table thead tr th.headerCell {
  text-transform: capitalize;
}

tr td.emptyLine {
  text-align: center;
  color: #8f8f8f;
  font-size: 16px;
  font-weight: 600;
}

.pill {
  border-radius: 15px;
  width: auto;
  height: 30px;
  cursor: default;
  font-weight: 600;
  font-size: 13px;
}

.row:hover {
  .removeIcon {
    opacity: 1;
  }
}

.removeIcon {
  opacity: 0;
  transition: color 0.2s ease-in-out, opacity 0.5s ease-in-out;
  &:hover {
    color: #222;
  }

  &:focus {
    opacity: 1;
    color: #222;
  }
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all 0.2s ease-in-out;

  &.pendingAddition {
    opacity: 0.5;
  }

  &.lock {
    opacity: 0.5;
  }

  &.pendingRemoval {
    opacity: 0.5;
    text-decoration: line-through;
  }
}

.lockButton {
  transition: border-color 0.3s ease-in-out, color 0.3s ease-in-out;
  &:hover {
    border-color: #dc2c10;
    color: #dc2c10;
  }
}

.tooltipFix {
  span {
    max-width: 100%;
  }
}
